const ProductIds = {
  BasicPackage: 640971,
  BasicToPlusUpgrade: 849953,
  BasicToPremiumUpgrade: 829718,
  PlusToPremiumUpgrade: 886184,
  PlusPackage: 640970,
  PremiumPakcage: 640957,
  CompleteBundleUpgrade: 646823,
  TestSlsApps: 795031,
}

const VendorId = 124878

export {
  ProductIds,
  VendorId,
}