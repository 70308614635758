<template>
  <div id="pricing" class="mt-32">
    <!-- <p class="mt-4 mb-16 text-lg md:text-xl text-center">Prices are inclusive of local sales tax.</p> -->

    <div class="max-w-7xl mx-auto mt-14 sm:px-6 lg:px-8">
      <!-- xs to lg -->
      <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
        <section>
          <div class="px-4 mb-8">
            <h2 class="text-xl leading-6 font-medium ">Basic</h2>
            <p class="mt-4">
              <span class="text-4xl font-extrabold ">{{ basicPrice }} {{ currency }}</span>
            </p>
            <span class="text-xl font-medium" 
              v-if="basicPriceTax > 0">+ sales tax: {{ basicPriceTax }} {{ currency }}</span>
          </div>

          <table class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Build the core Twitter features such as tweeting, timelines and following users, 
              while learning the basics of GraphQL and AppSync.
            </caption>
            <tbody class="divide-y divide-gray-200">
              <a href="#"
                class="relative bg-transparent mt-6 block w-full py-2 font-semibold text-white text-center"
                @click.prevent="showBasicDetails = !showBasicDetails">
                <span>Show me the details</span>

                <svg :class="`w-3 absolute right-5 top-2 fill-white ${ showBasicDetails ? 'rotate-90' : '' }`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/>
                </svg>
              </a>
            </tbody>
          </table>

          <table v-if="showBasicDetails" class="w-full">
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Free preview`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Lifetime access`" v-bind:included=true />
            </tbody>
          </table>          

          <table v-if="showBasicDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Build Twitter Features
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`User Registration`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Manage user profile`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Upload images`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Post tweets`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Timeline`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Reply/Retweet/Like`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Follow/unfollow users`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Search`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Hash tags`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Notifications`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Direct messages`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Two way pagination`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Analytics tracking`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showBasicDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Learn GraphQL/AppSync
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`What is GraphQL`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`What is AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`API Gateway vs AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Model Twitter with GraphQL schema`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`GraphQL subscriptions`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Model multi-tenant APIs`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showBasicDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Get production-ready
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`CI/CD`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Caching`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Optimizing Lambda cold starts`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Custom domain names`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Versioning strategies`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Detailed resolver metrics`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Set up CloudWatch dashboards`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Logging and Monitoring best practices`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`X-Ray integration`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Progressive web application`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showBasicDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Support
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Live Q&As`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Get help through forum`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showBasicDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Bonus courses
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Complete guide to AWS Step Functions`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Learn you some Lambda best practice for great good!`" v-bind:included=true />
            </tbody>
          </table>

          <div class="border-t border-gray-200 px-4 pt-5">
            <a href="#"
              class="checkout-button button mt-6 block w-full py-2 text-sm font-semibold text-center"
              @click.prevent="buyBasic">Buy Basic</a>
          </div>
        </section>

        <section>
          <div class="px-4 mb-8">
            <h2 class="text-xl leading-6 font-medium ">Plus</h2>
            <p class="mt-4">
              <span class="text-4xl font-extrabold ">{{ plusPrice }} {{ currency }}</span>
            </p>
            <span class="text-xl font-medium" 
              v-if="plusPriceTax > 0">+ sales tax: {{ plusPriceTax }} {{ currency }}</span>
          </div>

          <table class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Go beyond the basics and learn how to build real-time and search features, 
              and learn how to optimize your API for cost, scalability and performance.
            </caption>
            <tbody class="divide-y divide-gray-200">
              <a href="#"
                class="relative bg-transparent mt-6 block w-full py-2 font-semibold text-white text-center"
                @click.prevent="showPlusDetails = !showPlusDetails">
                <span>Show me the details</span>

                <svg :class="`w-3 absolute right-5 top-2 fill-white ${ showPlusDetails ? 'rotate-90' : '' }`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/>
                </svg>
              </a>
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Free preview`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Lifetime access`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Build Twitter Features
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`User Registration`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Manage user profile`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Upload images`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Post tweets`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Timeline`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Reply/Retweet/Like`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Follow/unfollow users`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Search`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Hash tags`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Notifications`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Direct messages`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Two way pagination`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Analytics tracking`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Learn GraphQL/AppSync
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`What is GraphQL`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`What is AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`API Gateway vs AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Model Twitter with GraphQL schema`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`GraphQL subscriptions`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Model multi-tenant APIs`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Get production-ready
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`CI/CD`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Caching`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Optimizing Lambda cold starts`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Custom domain names`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Versioning strategies`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Detailed resolver metrics`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Set up CloudWatch dashboards`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Logging and Monitoring best practices`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`X-Ray integration`" v-bind:included=false />
              <PricingFeatureSingle v-bind:name="`Progressive web application`" v-bind:included=false />
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Support
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Live Q&As`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Get help through forum`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPlusDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Bonus courses
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Complete guide to AWS Step Functions`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Learn you some Lambda best practice for great good!`" v-bind:included=true />
            </tbody>
          </table>

          <div class="border-t border-gray-200 px-4 pt-5">
            <a href="#"
              class="checkout-button button mt-6 block w-full py-2 text-sm font-semibold text-center"
              @click.prevent="buyPlus">Buy Plus</a>
          </div>
        </section>

        <section>
          <div class="px-4 mb-8">
            <h2 class="text-xl leading-6 font-medium ">Premium</h2>
            <p class="mt-4">
              <span class="text-4xl font-extrabold ">{{ premiumPrice }} {{ currency }}</span>
            </p>
            <span class="text-xl font-medium" 
              v-if="premiumPriceTax > 0">+ sales tax: {{ premiumPriceTax }} {{ currency }}</span>
          </div>

          <table class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              If you plan to run AppSync in production then this is the package for you.
            </caption>
            <tbody class="divide-y divide-gray-200">
              <a href="#"
                class="relative bg-transparent mt-6 block w-full py-2 font-semibold text-white text-center"
                @click.prevent="showPremiumDetails = !showPremiumDetails">
                <span>Show me the details</span>

                <svg :class="`w-3 absolute right-5 top-2 fill-white ${ showPremiumDetails ? 'rotate-90' : '' }`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/>
                </svg>
              </a>
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Free preview`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Lifetime access`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Build Twitter Features
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`User Registration`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Manage user profile`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Upload images`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Post tweets`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Timeline`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Reply/Retweet/Like`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Follow/unfollow users`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Search`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Hash tags`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Notifications`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Direct messages`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Two way pagination`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Analytics tracking`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Learn GraphQL/AppSync
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`What is GraphQL`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`What is AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`API Gateway vs AppSync`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Model Twitter with GraphQL schema`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`GraphQL subscriptions`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Model multi-tenant APIs`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Get production-ready
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`CI/CD`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Caching`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Optimizing Lambda cold starts`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Custom domain names`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Versioning strategies`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Detailed resolver metrics`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Set up CloudWatch dashboards`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Logging and Monitoring best practices`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`X-Ray integration`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Progressive web application`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Support
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Live Q&As`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Get help through forum`" v-bind:included=true />
            </tbody>
          </table>

          <table v-if="showPremiumDetails" class="w-full">
            <caption class="text-lg border-t border-gray-200 py-3 px-4 font-medium text-left">
              Bonus courses
            </caption>
            <thead>
              <tr>
                <th class="sr-only" scope="col">Feature</th>
                <th class="sr-only" scope="col">Included</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <PricingFeatureSingle v-bind:name="`Complete guide to AWS Step Functions`" v-bind:included=true />
              <PricingFeatureSingle v-bind:name="`Learn you some Lambda best practice for great good!`" v-bind:included=true />
            </tbody>
          </table>

          <div class="border-t border-gray-200 px-4 pt-5">
            <a href="#"
              class="checkout-button button mt-6 block w-full py-2 text-sm font-semibold text-center"
              @click.prevent="buyPremium">Buy Premium</a>
          </div>
        </section>
      </div>

      <!-- lg+ -->
      <div class="hidden lg:block">
        <table class="w-full h-px table-fixed">
          <caption class="sr-only">
            Package comparison
          </caption>
          <thead>
            <tr>
              <th class="pb-4 px-6 text-sm font-medium text-left" scope="col">
                <span class="sr-only">Feature by</span>
                <span>Packages</span>
              </th>

              <th class="w-1/4 pb-4 px-6 text-xl leading-6 font-bold text-left" scope="col">Basic</th>
              <th class="w-1/4 pb-4 px-6 text-xl leading-6 font-bold text-left" scope="col">Plus</th>
              <th class="w-1/4 pb-4 px-6 text-xl leading-6 font-bold text-left" scope="col">Premium</th>
            </tr>
          </thead>
          <tbody class="border-t border-gray-200 divide-y divide-gray-200">
            <tr>
              <th class="py-8 px-6 text-lg font-bold text-left align-top" scope="row">Pricing</th>

              <td class="h-full py-8 px-6 align-top">
                <div class="relative h-full table w-full">
                  <p>
                    <span class="text-4xl font-extrabold">{{ basicPrice }} {{ currency }}</span>
                  </p>
                  <p class="text-xl" 
                    v-if="basicPriceTax > 0">+ sales tax: {{ basicPriceTax }} {{ currency }}
                  </p>
                  <a href="#"
                    class="mt-5 checkout-button button block w-full py-2 text-lg font-semibold text-center"
                    @click.prevent="buyBasic">Buy Basic</a>
                  <p class="mt-5">
                    Build the core Twitter features such as tweeting, timelines and following 
                    users, while learning the basics of GraphQL and AppSync.
                  </p>
                </div>
              </td>

              <td class="h-full py-8 px-6 align-top">
                <div class="relative h-full table w-full">
                  <p>
                    <span class="text-4xl font-extrabold ">{{ plusPrice }} {{ currency }}</span>
                  </p>
                  <p class="text-xl" 
                    v-if="plusPriceTax > 0">+ sales tax: {{ plusPriceTax }} {{ currency }}</p>
                  <a href="#"
                   class="checkout-button mt-5 button block w-full py-2 text-lg font-semibold text-center"
                   @click.prevent="buyPlus">Buy Plus</a>
                  <p class="mt-5">
                    Go beyond the basics and learn how to build real-time features, 
                    and learn how to optimize your API for cost, scalability and performance.
                  </p>
                </div>
              </td>

              <td class="h-full py-8 px-6 align-top">
                <div class="relative h-full table w-full">
                  <p>
                    <span class="text-4xl font-extrabold ">{{ premiumPrice }} {{ currency }}</span>
                  </p>
                  <p class="text-xl" 
                    v-if="premiumPriceTax > 0">+ sales tax: {{ premiumPriceTax }} {{ currency }}</p>
                  <a href="#"
                    class="mt-5 checkout-button button block w-full py-2 text-lg font-semibold text-center"
                    @click.prevent="buyPremium">Buy Premium</a>
                  <p class="mt-5">
                    If you plan to run AppSync in production then this is the package for you.
                  </p>
                </div>
              </td>
            </tr>

            <PricingFeature v-bind:name="`Free preview`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Lifetime access`" v-bind:inBasic=true v-bind:inPlus=true />

            <tr id="packages">
              <th class="pt-10 pb-3 pl-6 text-lg font-bold text-left" colspan="4" scope="colgroup">
                Build Twitter Features</th>
            </tr>

            <PricingFeature v-bind:name="`User registration`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Manage user profile`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Upload images`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Post tweets`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Timeline`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Reply/Retweet/Like`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Follow/unfollow users`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Search`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Hash tags`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Notifications`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Direct messages`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Two way pagination`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Analytics tracking`" v-bind:inBasic=false v-bind:inPlus=false />

            <tr>
              <th class="pt-10 pb-3 pl-6 text-lg font-bold text-left" colspan="4" scope="colgroup">
                Learn GraphQL/AppSync</th>
            </tr>

            <PricingFeature v-bind:name="`What is GraphQL`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`What is AppSync`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`API Gateway vs AppSync`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Model Twitter with GraphQL schema`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`GraphQL subscriptions`" v-bind:inBasic=false v-bind:inPlus=true />            
            <PricingFeature v-bind:name="`Model multi-tenant APIs`" v-bind:inBasic=false v-bind:inPlus=false />

            <tr>
              <th class="pt-10 pb-3 pl-6 text-lg font-bold text-left" colspan="4" scope="colgroup">
                Get production-ready</th>
            </tr>

            <PricingFeature v-bind:name="`CI/CD`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Caching`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Optimizing Lambda cold starts`" v-bind:inBasic=false v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Custom domain names`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`Versioning strategies`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`Detailed resolver metrics`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`Set up CloudWatch dashboards`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`Logging and Monitoring best practices`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`X-Ray integration`" v-bind:inBasic=false v-bind:inPlus=false />
            <PricingFeature v-bind:name="`Progressive web application`" v-bind:inBasic=false v-bind:inPlus=false />

            <tr>
              <th class="pt-10 pb-3 pl-6 text-lg font-bold text-left" colspan="4" scope="colgroup">
                Support</th>
            </tr>

            <PricingFeature v-bind:name="`Live Q&As`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Get help through forum`" v-bind:inBasic=true v-bind:inPlus=true />

            <tr>
              <th class="pt-10 pb-3 pl-6 text-lg font-bold text-left" colspan="4" scope="colgroup">
                Bonus courses (see details below)</th>
            </tr>

            <PricingFeature v-bind:name="`Complete guide to AWS Step Functions`" v-bind:inBasic=true v-bind:inPlus=true />
            <PricingFeature v-bind:name="`Learn you some Lambda best practice for great good!`" v-bind:inBasic=true v-bind:inPlus=true />

          </tbody>
          <tfoot>
            <tr class="border-t border-gray-200">
              <th class="sr-only" scope="row">Choose your package</th>

              <td class="pt-5 px-6">
                <a href="#"
                  class="checkout-button button block w-full py-2 text-lg font-semibold text-center"
                  @click.prevent="buyBasic">Buy Basic</a>

                <span>See detailed curriculum and sign up for free preview 
                  <a class="link" href="https://theburningmonk.thinkific.com/courses/appsync-masterclass-basic?source=AppSyncMasterclass" target="_blank" @click="trackEvent('preview-basic')">here</a>.
                </span>
              </td>

              <td class="pt-5 px-6">
                <a href="#"
                   class="checkout-button button block w-full py-2 text-lg font-semibold text-center"
                   @click.prevent="buyPlus">Buy Plus</a>

                <span>See detailed curriculum and sign up for free preview 
                  <a class="link" href="https://theburningmonk.thinkific.com/courses/appsync-masterclass-plus?source=AppSyncMasterclass" target="_blank" @click="trackEvent('preview-plus')">here</a>.
                </span>
              </td>

              <td class="pt-5 px-6">
                <a href="#"
                   class="checkout-button button block w-full py-2 text-lg font-semibold text-center"
                   @click.prevent="buyPremium">Buy Premium</a>

                <span>See detailed curriculum and sign up for free preview 
                  <a class="link" href="https://theburningmonk.thinkific.com/courses/appsync-masterclass-premium?source=AppSyncMasterclass" target="_blank" @click="trackEvent('preview-premium')">here</a>.
                </span>
              </td>
            </tr>
          </tfoot>
        </table>


      </div>
    </div>

    <HappinessGuarantee />

    <AffiliateProgram />

    <TeamLicenses />

    <OtherCourses />
  </div>
</template>

<script>
import PricingFeature from './PricingFeature.vue'
import PricingFeatureSingle from './PricingFeatureSingle.vue'
import HappinessGuarantee from './HappinessGuarantee.vue'
import AffiliateProgram from './AffiliateProgram.vue'
import TeamLicenses from './TeamLicenses.vue'
import OtherCourses from './OtherCourses.vue'
import { ProductIds, VendorId } from '../libs/constants'
import { getCookie } from '../libs/utils'

export default {
  name: 'PricingComponent',
  components: {
    PricingFeature,
    PricingFeatureSingle,
    HappinessGuarantee,
    AffiliateProgram,
    TeamLicenses,
    OtherCourses,
  },
  data() {
    return {
      currency: "...checking",
      basicPrice: undefined,
      basicPriceTax: undefined,
      plusPrice: undefined,
      plusPriceTax: undefined,
      premiumPrice: undefined,
      premiumPriceTax: undefined,
      bundlePrice: undefined,
      bundlePriceFull: undefined,
      showBasicDetails: false,
      showPlusDetails: false,
      showPremiumDetails: false,
    }
  },
  async mounted() {
    const prices = await this.getPrices()

    const findProduct = productId => prices.products.find(x => x.product_id === productId)

		const basic = findProduct(ProductIds.BasicPackage)
		const plus = findProduct(ProductIds.PlusPackage)
    const premium = findProduct(ProductIds.PremiumPakcage)

    this.currency = basic.currency
    this.basicPrice = basic.price.net
    this.basicPriceTax = basic.price.tax
    this.plusPrice = plus.price.net
    this.plusPriceTax = plus.price.tax
    this.premiumPrice = premium.price.net
    this.premiumPriceTax = premium.price.tax

    global.Paddle.Setup({ vendor: VendorId })
  },
  methods: {
    async getPrices() {
      try {
        const productIds = Object.values(ProductIds).join(',')
        const url = `https://d16oubqwv0esey.cloudfront.net/api/2.0/prices?product_ids=${productIds}`

        const result = await fetch(url).then(x => x.json())
        if (!result.success) {
          console.error('Paddle returned a failure response')
          return null
        }

        const prices = result.response
        console.log(prices)
  
        return prices
      } catch(err) {
        console.error('failed to get prices from Paddle', err)
        return null
      }
    },

    buyBasic() {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }
      global.Paddle.Checkout.open({ 
        product: ProductIds.BasicPackage,
        passthrough: JSON.stringify(passthrough)
      })
      this.trackEvent('click-buy-basic')
    },

    buyPlus() {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }
      global.Paddle.Checkout.open({ 
        product: ProductIds.PlusPackage,
        passthrough: JSON.stringify(passthrough)
      })      
      this.trackEvent('click-buy-plus')
    },

    buyPremium() {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }
      global.Paddle.Checkout.open({ 
        product: ProductIds.PremiumPakcage,
        passthrough: JSON.stringify(passthrough)
      })
      this.trackEvent('click-buy-premium')
    },

    trackEvent(eventName) {
      switch (eventName) {
        case 'preview-basic':
          global.fathom.trackGoal('J6G9IXRK', 0)
          break
        case 'preview-plus':
          global.fathom.trackGoal('NBJ8OIBJ', 0)
          break
        case 'preview-premium':
          global.fathom.trackGoal('RO3QMK0Y', 0)
          break
        case 'click-buy-basic':
          global.fathom.trackGoal('CZEICRS6', 0)
          break
        case 'click-buy-plus':
          global.fathom.trackGoal('RKCPN0JC', 0)
          break
        case 'click-buy-premium':
          global.fathom.trackGoal('BXAKFRRE', 0)
          break
        default:
          break
      }
    }
  }
}
</script>