<template>
  <div class="flex flex-col items-center mt-5 mb-10">
    <img class="h-64 w-64" loading="lazy" src="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto,c_scale,h_256,w_256/affiliate-program.png" />

    <h2 class="text-xl text-center mt-4 md:w-1/2">
      If you have enjoyed my courses or workshops, why not become an affiliate
      and earn <strong>50% commission</strong> when you refer a friend?
      You can apply to join the program
      <a class="link underline" target="_blank"
        href="https://affiliates.theburningmonk.com/apply">
        here
      </a>
    </h2>
  </div>
</template>
