<template>
  <div id="faq" class="mt-32">
    <div class="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-4xl font-extrabold">FAQs</h2>
      <div class="mt-6 pt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
          <div>
            <dt class="text-3xl font-bold">
              What if I don't like it?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              No problems. If you don't like it, you can get a full refund within
              <span class="font-extrabold text-white">30 days</span> of your 
              purchase with no questions asked.</dd>
          </div>
          
          <div>
            <dt class="text-3xl font-bold">
              Is there a way I can talk to someone if I get stuck?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              You can reach out to me or your fellow students via the course forum. 
              There are always people willing to help each other out, and I keep a 
              close eye on the forum as well.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              What are the prerequisites for taking this course?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              As both the frontend and backend components are written in JavaScript, 
              you should have a working knowledge of it and be able to read basic 
              JavaScript - variable assignment, calling methods, for loops, etc. 
              We will teach you the basics of the AWS services you will use, 
              including the fundamentals of IAM, CloudFormation, Lambda and DynamoDB. 
              You need to have access to an AWS account, with administrator access, 
              so you will not have any problems with creating resources in the account.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              How is this different from other courses on the market?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              The main difference is that I am the one teaching it. I have lived 
              and breathed serverless for a long time and everything I teach you 
              come from years of experience of running this in production. This 
              is not a surface-level type of tutorial, it's in-depth and intensive, 
              and teaches you skills you will need in the real-world. These 
              practical skills are also highly sought after in the market and 
              valued by employers all over.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Can I preview the content before I buy?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, you can. There is a free preview in each of the packages where 
              you can access a number of lessons for free. Go to the bottom of the 
              <a href="#pricing" class="link">pricing</a> section for the links to 
              sign up for free preview.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Is there any kind of certificate of completion?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, there is! Once you complete the course, you will receive a 
              <span class="font-extrabold text-white">certificate of completion</span> 
              via email.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Is there an upgrade path if I change my mind later and want a higher package?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, there is! If you want to upgrade to a higher package (hopefully 
              that means you see the value of this course!) then drop me an email at 
              <a class="link" target="_blank"
                href="mailto:theburningmonk@hey.com?subject=Upgrade%20AppSync%20Masterclass">
                theburningmonk@hey.com</a> 
              with a copy of your original invoice and I'll sort it out for you.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              I can't afford your course, is there anything you can do to help?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, absolutely. Drop me an email at 
              <a class="link" target="_blank"
                href="mailto:theburningmonk@hey.com?subject=Help%20with%20purchasing%20AppSync%20Masterclass">
                theburningmonk@hey.com</a> 
              and explain your situation to me. Maybe you're a student, or you're 
              out-of-work, or maybe the price is just too high for your country, 
              whatever the case, I would love to help you out.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Do you support Purchase Power Parity (PPP)?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, we support PPP for <span class="text-white font-bold">161 countries</span>.
              You should see a banner at the top of the website with a discount code for 
              your country.
              <span class="text-white font-bold">
                Please make sure that you're not connected through a VPN.
              </span>
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Do you have a Privacy Policy?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, you can see our Privacy Policy 
              <a class="link" target="_blank" 
                href="https://app.termly.io/document/privacy-policy/eccb8f0e-3062-4ae7-9e89-16dffba7d9c1">
                here</a>.
            </dd>
          </div>

          <div>
            <dt class="text-3xl font-bold">
              Do you have a Terms and Conditions (T&C)?
            </dt>
            <dd class="mt-2 text-lg text-gray-400 hover:text-white">
              Yes, you can see our Terms and Conditions 
              <a class="link" target="_blank" 
                href="https://app.termly.io/document/terms-of-use-for-website/34f1a17e-8c80-4900-b114-9d838f6ebbad">
                here</a>.
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqComponent'
}
</script>
