<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #EDF2F2;
  background-color: #151515;
}

#nav {
  padding: 30px;
}

.button {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-color: transparent;
  border-radius: 0.375rem;
  font-weight: 600;
}

.button:hover {
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.link {
  font-weight: bold;
  text-decoration: underline;
  color: #ffffff;
}
</style>
