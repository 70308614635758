<template>
  <div class="flex flex-col items-center mt-5 mb-10">
    <img class="h-64 w-64" loading="lazy" src="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto,c_scale,h_256,w_256/happiness-guarantee.png" />

    <h2 class="text-xl text-center md:w-1/2">
      If this course doesn't meet your expectations, you're entitled to a <strong>FULL REFUND</strong>.
      within 30 days. We promise a hassle-free refund, no questions asked.
    </h2>
  </div>
</template>
