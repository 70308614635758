<template>
  <li class="relative flex justify-center items-center h-36 md:h-44">
    <img class="absolute h-16 opacity-60 hover:h-20 hover:opacity-100" 
      loading="lazy"
      :src="`https://res.cloudinary.com/appsyncmasterclass/image/upload/${logo}`" 
      :alt="`${ company }`"
      @mouseover="hover = true"
      @mouseleave="hover = false">
    <p v-if="hover === true" class="absolute bottom-0 text-xl">{{ company }}</p>
  </li>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: ['company', 'logo'],
  data() {
    return {
      hover: false
    }
  }
}
</script>

<style scoped>
img {
  transition: all 0.2s ease-out;
}
</style>