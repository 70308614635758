import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import ThankYouView from '../views/ThankYou.vue'
import CheckOutBasicView from '../views/CheckOutBasic.vue'
import CheckOutPlusView from '../views/CheckOutPlus.vue'
import CheckOutPremiumView from '../views/CheckOutPremium.vue'
import CheckOutBasicToPlusUpgradeView from '../views/CheckOutBasicToPlusUpgrade.vue'
import CheckOutBasicToPremiumUpgradeView from '../views/CheckOutBasicToPremiumUpgrade.vue'
import CheckOutPlusToPremiumUpgradeView from '../views/CheckOutPlusToPremiumUpgrade.vue'

const routes = [
  { path: '/', name: 'Home', component: HomeView },
  { path: '/thank-you', name: 'ThankYou', component: ThankYouView },
  { path: '/check-out-basic', name: 'CheckOutBasic', component: CheckOutBasicView },
  { path: '/check-out-plus', name: 'CheckOutPlus', component: CheckOutPlusView },
  { path: '/check-out-premium', name: 'CheckOutPremium', component: CheckOutPremiumView },
  { path: '/check-out-basic-to-plus', name: 'CheckoutBasicToPlusUpgrade', component: CheckOutBasicToPlusUpgradeView },
  { path: '/check-out-basic-to-premium', name: 'CheckoutBasicToPremiumUpgrade', component: CheckOutBasicToPremiumUpgradeView },
  { path: '/check-out-plus-to-premium', name: 'CheckoutPlusToPremiumUpgrade', component: CheckOutPlusToPremiumUpgradeView },
  { path: '/:pathMatch(.*)', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { 
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
})

export default router
