<template>
  <div id="team-licenses" class="mt-14">
    <div class="text-center">
      <h3 class="text-white text-4xl font-bold">Team licenses</h3>
      <p>Buying for a team? Get multiples copies of the AppSync Masterclass premium package and save up to <span class="font-extrabold text-lg">35% OFF</span> with our team licenses.</p>

      <div class="max-w-2xl mx-auto mt-3">
        <a class="relative bg-transparent block py-3 border rounded-md text-lg font-medium" href="#" 
          @click.prevent="toggleShowLicenses()">
          <span>Show me the savings</span>

          <svg :class="`w-3 absolute right-5 top-4 fill-white ${ showLicenses ? 'rotate-90' : '' }`" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/>
          </svg>
        </a>

        <ul class="mt-2 flex flex-col" v-if="showLicenses">
          <li class="w-64 mx-auto flex flex-row justify-between items-center my-2"
            v-bind:key="license.size"
            v-for="license of teamLicenses">
            <a class="text-lg button border py-2 w-32 rounded-md" href="#" 
              @click.prevent="openCheckout(license.size)">Team of {{license.size}}</a>
            <span class="text-lg font-bold">{{license.saving}}% saving</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '../libs/utils'

export default {
  data() {
    return {
      showLicenses: false,
      trackedGoal: false,
      teamLicenses: [
        { size: 3, saving: 20 }, 
        { size: 5, saving: 22 }, 
        { size: 10, saving: 24 }, 
        { size: 15, saving: 26 },
        { size: 20, saving: 28 },
        { size: 25, saving: 30 },
        { size: 50, saving: 35 },
      ]
    }
  },
  methods: {
    toggleShowLicenses() {
      this.showLicenses = !this.showLicenses

      if (!this.trackedGoal) {        
        global.fathom.trackGoal('39EJ8RMO', 0)
        this.trackedGoal = true
      }
    },

    openCheckout(size) {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }

      switch (size) {
        case 3:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMjAlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTYwIiwiR0JQIjoiMTMzLjQ3IiwiRVVSIjoiMTUxLjM2IiwiQVVEIjoiMjQ1LjM0IiwiQlJMIjoiODU2LjI1IiwiQ0FEIjoiMjI1LjI3IiwiQ0hGIjoiMTUwLjE2IiwiUExOIjoiNzExLjE2IiwiU0VLIjoiMTczOS4xOCIsIlpBUiI6IjI5NjUuMzkiLCJDWksiOiIzNTkwLjg3IiwiSFVGIjoiNTgzMzQuMjEiLCJES0siOiIxMTMwLjIzIiwiTlpEIjoiMjYzLjAxIiwiU0dEIjoiMjE3LjUzIiwiSEtEIjoiMTI4MS4xMSIsIklOUiI6IjEzNDM5LjEiLCJKUFkiOiIyMTMzOCIsIktSVyI6IjIxMTQ4MCIsIk5PSyI6IjE3MDguMzgiLCJUSEIiOiI1NTYzLjY4IiwiSUxTIjoiNTg2LjY0In0sInEiOjAsInEyIjozLCJkIjoxLCJhIjpbXSwidiI6IjEyNDg3OCIsInMiOiI1YzM5ODliNmM0MzllM2MxNDcwYWExMjA1MzQ3ZDg5MTBjOGE5ZjdjYTQ3OWVhMWFkMjE3YjJmZTE3ZWExNDQ2NGZjZjgzNWNmMmRhYjIwYTM5NWQ4MjY0NzMyNzY5YzQ4MDVkNTNiNmRlMDRiNWQ5YzljYzlhYTM0OWE4NWNjMCJ9"            
          })

          global.fathom.trackGoal('0JJFU0QI', 0)

          break
        case 5:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMjIlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTU2IiwiR0JQIjoiMTMwLjE0IiwiRVVSIjoiMTQ3LjU4IiwiQVVEIjoiMjM5LjIxIiwiQlJMIjoiODM0Ljg0IiwiQ0FEIjoiMjE5LjY0IiwiQ0hGIjoiMTQ2LjQxIiwiUExOIjoiNjkzLjM4IiwiU0VLIjoiMTY5NS43IiwiWkFSIjoiMjg5MS4yNiIsIkNaSyI6IjM1MDEuMSIsIkhVRiI6IjU2ODc1Ljg1IiwiREtLIjoiMTEwMS45NyIsIk5aRCI6IjI1Ni40NCIsIlNHRCI6IjIxMi4wOSIsIkhLRCI6IjEyNDkuMDgiLCJJTlIiOiIxMzEwMy4xMyIsIkpQWSI6IjIwODA1IiwiS1JXIjoiMjA2MTkzIiwiTk9LIjoiMTY2NS42NyIsIlRIQiI6IjU0MjQuNTkiLCJJTFMiOiI1NzEuOTcifSwicSI6MCwicTIiOjUsImQiOjEsImEiOltdLCJ2IjoiMTI0ODc4IiwicyI6ImI5YTAzNDM5ZWFlNTdhYWNhN2ZlZWQ1MDk1ZjRjOWEyZTM5YWY1YTRmMzJjMjljMmU2MjQxZmVjYWRiZDRlNzFkYzMzMmQzOTIxYTIwN2Q4ZDMzOWI1MzY4MTBkNTAzMjU2Y2YyN2NkZTM5NWFkZGI1YjQ2MTU5ZWU5YjVlNTU3In0="
          })

          global.fathom.trackGoal('JRIQ7CRO', 0)

          break
        case 10:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMjQlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTUyIiwiR0JQIjoiMTI2LjgiLCJFVVIiOiIxNDMuNzkiLCJBVUQiOiIyMzMuMDciLCJCUkwiOiI4MTMuNDMiLCJDQUQiOiIyMTQuMDEiLCJDSEYiOiIxNDIuNjUiLCJQTE4iOiI2NzUuNjEiLCJTRUsiOiIxNjUyLjIyIiwiWkFSIjoiMjgxNy4xMiIsIkNaSyI6IjM0MTEuMzMiLCJIVUYiOiI1NTQxNy41IiwiREtLIjoiMTA3My43MSIsIk5aRCI6IjI0OS44NiIsIlNHRCI6IjIwNi42NSIsIkhLRCI6IjEyMTcuMDYiLCJJTlIiOiIxMjc2Ny4xNSIsIkpQWSI6IjIwMjcxIiwiS1JXIjoiMjAwOTA2IiwiTk9LIjoiMTYyMi45NiIsIlRIQiI6IjUyODUuNSIsIklMUyI6IjU1Ny4zMSJ9LCJxIjowLCJxMiI6MTAsImQiOjEsImEiOltdLCJ2IjoiMTI0ODc4IiwicyI6ImMyN2ZmZWJkN2U3ZjZkZDBhOWI5ZDlhYTY5ODE5OGE3YzgyZGYwMmEyNTFkNzdlM2RjNzFmMTMwZjllYWMyZDk1MTg2YTMwNDIyNzZiNWRkZGI4YTM0NWFkYjRkODY1M2ZlN2VlYTU2ODIzY2EwZmE3ZTJlM2RkYmU3MjI0MWIwIn0="
          })

          global.fathom.trackGoal('H8TT1OGQ', 0)

          break
        case 15:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMjYlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTQ4IiwiR0JQIjoiMTIzLjQ2IiwiRVVSIjoiMTQwLjAxIiwiQVVEIjoiMjI2Ljk0IiwiQlJMIjoiNzkyLjAzIiwiQ0FEIjoiMjA4LjM4IiwiQ0hGIjoiMTM4LjkiLCJQTE4iOiI2NTcuODMiLCJTRUsiOiIxNjA4Ljc0IiwiWkFSIjoiMjc0Mi45OSIsIkNaSyI6IjMzMjEuNTYiLCJIVUYiOiI1Mzk1OS4xNCIsIkRLSyI6IjEwNDUuNDYiLCJOWkQiOiIyNDMuMjkiLCJTR0QiOiIyMDEuMjEiLCJIS0QiOiIxMTg1LjAzIiwiSU5SIjoiMTI0MzEuMTciLCJKUFkiOiIxOTczOCIsIktSVyI6IjE5NTYxOSIsIk5PSyI6IjE1ODAuMjUiLCJUSEIiOiI1MTQ2LjQiLCJJTFMiOiI1NDIuNjQifSwicSI6MCwicTIiOjE1LCJkIjoxLCJhIjpbXSwidiI6IjEyNDg3OCIsInMiOiIyZTU4ZWQ5ZWFlYjA5NmM4MDI1NjBlMTA3YmNmMzA5N2UwNjAzYTM1ZGQ5ZjJjOThhZmFkZmVlYzRlM2ZkZDAzNmRjMTUxOTFjZDQ3OWFlYjM5MmZiM2QyZDVhYTE1OGYyNmMyODg4OTIyZTUxMzJkZDQ3YjQxMjAwMzZiNzJhNyJ9"
          })

          global.fathom.trackGoal('3WEZWIDA', 0)

          break
        case 20:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMjglIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTQ0IiwiR0JQIjoiMTIwLjEzIiwiRVVSIjoiMTM2LjIzIiwiQVVEIjoiMjIwLjgxIiwiQlJMIjoiNzcwLjYyIiwiQ0FEIjoiMjAyLjc1IiwiQ0hGIjoiMTM1LjE0IiwiUExOIjoiNjQwLjA1IiwiU0VLIjoiMTU2NS4yNiIsIlpBUiI6IjI2NjguODUiLCJDWksiOiIzMjMxLjc5IiwiSFVGIjoiNTI1MDAuNzkiLCJES0siOiIxMDE3LjIiLCJOWkQiOiIyMzYuNzEiLCJTR0QiOiIxOTUuNzgiLCJIS0QiOiIxMTUzIiwiSU5SIjoiMTIwOTUuMTkiLCJKUFkiOiIxOTIwNSIsIktSVyI6IjE5MDMzMiIsIk5PSyI6IjE1MzcuNTQiLCJUSEIiOiI1MDA3LjMxIiwiSUxTIjoiNTI3Ljk4In0sInEiOjAsInEyIjoyMCwiZCI6MSwiYSI6W10sInYiOiIxMjQ4NzgiLCJzIjoiNTA0MDNkYjBiMWNiNmQ0MGUxODYzYTBlMDRlNTM2NzcyYTA5OWFjM2I3ZTI5Y2ExNWM1YjM3ZTZkMDA3ZjliM2ZlODllMWMzOWNjYjU2ZGE2NDkxN2M2MTQ3Mzk1OTJjNzc0NGYzNTRkMGJmYjMxYWYwYmE2MWQyMWU4ZWVjNmUifQ=="
          })

          global.fathom.trackGoal('TKAP9IVU', 0)

          break
        case 25:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMzAlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTQwIiwiR0JQIjoiMTE2Ljc5IiwiRVVSIjoiMTMyLjQ0IiwiQVVEIjoiMjE0LjY3IiwiQlJMIjoiNzQ5LjIxIiwiQ0FEIjoiMTk3LjExIiwiQ0hGIjoiMTMxLjM5IiwiUExOIjoiNjIyLjI3IiwiU0VLIjoiMTUyMS43OCIsIlpBUiI6IjI1OTQuNzIiLCJDWksiOiIzMTQyLjAxIiwiSFVGIjoiNTEwNDIuNDMiLCJES0siOiI5ODguOTUiLCJOWkQiOiIyMzAuMTQiLCJTR0QiOiIxOTAuMzQiLCJIS0QiOiIxMTIwLjk3IiwiSU5SIjoiMTE3NTkuMjIiLCJKUFkiOiIxODY3MSIsIktSVyI6IjE4NTA0NSIsIk5PSyI6IjE0OTQuODMiLCJUSEIiOiI0ODY4LjIyIiwiSUxTIjoiNTEzLjMxIn0sInEiOjAsInEyIjoyNSwiZCI6MSwiYSI6W10sInYiOiIxMjQ4NzgiLCJzIjoiNmU2ZjczMDcyMTAwOWIxYzExOTYzN2YyZmYwYjNmZDhkZjRiOTcwZjI0ZDNjY2MzYTdiMmI5MWMzZmZiYTBlMzRjZWY3M2M2Y2JjM2MwMDQ4MTg0NTRhM2MzYzZiM2NjYWViNjI4NDhjNGMwY2QwYjI0NWFkZDAxODFlYmQ2NzIifQ=="
          })

          global.fathom.trackGoal('4M31LQMG', 0)

          break
        case 50:
          global.Paddle.Checkout.open({ 
            passthrough: JSON.stringify(passthrough),
            override: "https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiQXBwU3luYyBNYXN0ZXJjbGFzcyBwcmVtaXVtIHBhY2thZ2UiLCJpIjoiaHR0cHM6XC9cL3BhZGRsZS5zMy5hbWF6b25hd3MuY29tXC91c2VyXC8xMjQ4NzhcLzVLeDZGUU5iU2Vpb0FSQzhVQktDX2FwcHN5bmMlMjBtYXN0ZXJjbGFzcy5wbmciLCJyIjpudWxsLCJjbSI6IlNhdmUgMzUlIiwicmUiOjEsInAiOjY0MDk1NywiY2MiOnsiVVNEIjoiMTMwIiwiR0JQIjoiMTA4LjQ1IiwiRVVSIjoiMTIyLjk4IiwiQVVEIjoiMTk5LjM0IiwiQlJMIjoiNjk1LjciLCJDQUQiOiIxODMuMDMiLCJDSEYiOiIxMjIuMDEiLCJQTE4iOiI1NzcuODIiLCJTRUsiOiIxNDEzLjA4IiwiWkFSIjoiMjQwOS4zOCIsIkNaSyI6IjI5MTcuNTgiLCJIVUYiOiI0NzM5Ni41NCIsIkRLSyI6IjkxOC4zMSIsIk5aRCI6IjIxMy43IiwiU0dEIjoiMTc2Ljc0IiwiSEtEIjoiMTA0MC45IiwiSU5SIjoiMTA5MTkuMjciLCJKUFkiOiIxNzMzNyIsIktSVyI6IjE3MTgyNyIsIk5PSyI6IjEzODguMDYiLCJUSEIiOiI0NTIwLjQ5IiwiSUxTIjoiNDc2LjY1In0sInEiOjAsInEyIjo1MCwiZCI6MSwiYSI6W10sInYiOiIxMjQ4NzgiLCJzIjoiODhlNzg1MDZkZmE2MDc4M2EzYTQ0NGJkMTdlMmUwNDQ3NmE1MGE5ZTAyZjhmNTQ3MzNjYTU3NGNhODk0NDZlOGNkNjkzODc1NGUzNzllNThmZmFkZDgwOTE0OGExNDVmMGZiNzU3YTNmYWNmMjlhOWJiNDJhNTJkNGMxYzNmMDQifQ=="
          })

          global.fathom.trackGoal('NIV4ZFIH', 0)

          break
        default:
          alert("Oops, we didn't recognize that team size. If you need help, please email theburningmonk@hey.com with your enquiry.")
          break
      }
    }
  }
}
</script>

<style scoped>
.table {
  transition: all 0.2s ease-out;
}
</style>
