<template>
  <div id="about" class="mt-32 mb-32 max-w-7xl mx-auto">
    <h1 class="text-5xl font-extrabold">Hey, I'm Yan.</h1>

    <div class="mt-14 grid grid-cols-1 lg:grid-cols-5 gap-y-5 lg:gap-x-20">
      <img class="w-28 rounded-full" loading="lazy"
        src="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/mugshot.png" />
      <p class="text-3xl col-span-2">
        I'm an <a class="font-extrabold" href="https://aws.amazon.com/developer/community/heroes/yan-cui/" target="_blank">AWS Serverless Hero</a>. I
        have run production workloads on AWS since 2010 and have helped
        thousands of developers get up to speed with AWS and serverless.
      </p>
      <span class="col-span-2 text-gray-400 md:text-lg lg:text-base"
        >I have been an architect and principal engineer with a variety of
        industries including banking, e-commerce, sports streaming and mobile
        gaming. Nowadays, I help clients adopt AWS and serverless as an
        independent consultant. I have implemented several complex GraphQL APIs
        with AppSync, including a new social network. I have designed this
        course so you too can unlock the power of GraphQL and serverless, while
        avoiding the mistakes I had made along the way!
      </span>
    </div>

    <div class="mt-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      <figure class="img-hover-zoom overlay relative" v-for="tile in tiles"  v-bind:key="tile.name">
        <figcaption class="z-50 absolute opacity-80 bottom-3 text-gray-400 text-center text-lg font-medium w-full">{{ tile.msg }}</figcaption>
        <img class="z-0" loading="lazy"
          :src="`https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/${tile.img}`" >
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
  data() {
    return {
      tiles: [{
        name: 'speaking',
        msg: "spoken at over 150 events",
        img: 'v1658840929/about-speaking.png'
      }, {
        name: 'work',
        msg: "worked on some complex systems",
        img: 'v1658840930/about-work.png'
      }, {
        name: 'workshops',
        msg: "led workshops all over",
        img: 'v1658840930/about-workshops.png'
      }, {
        name: 'book',
        msg: "written some books",
        img: 'v1658840929/about-book.png'
      }, {
        name: 'cat',
        msg: "I'm a cat guardian :-)",
        img: 'v1658840929/about-cat.png'
      }, {
        name: 'travel',
        msg: "love travelling",
        img: 'v1658840929/about-travel.png'
      }, {
        name: 'course',
        msg: "made a few online courses",
        img: 'v1658840929/about-course.png'
      }, {
        name: 'blog',
        msg: "blog consistently for over 10 years",
        img: 'v1658840929/about-blog.png'
      }, {
        name: 'podcast',
        msg: "run a podcast about serverless",
        img: 'v1658840929/about-podcast.png'
      }]
    }
  }
}
</script>

<style scoped>
.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  transition: transform 0.2s ease-out;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}

.overlay::before {
  content: '';
  z-index: 10;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%);
}
</style>