<template>
  <div id="other-courses" class="mt-14 hidden lg:grid grid-cols-1 justify-items-center gap-y-3">
    <p class="text-center text-white text-4xl font-bold">Check out my other courses</p>
    <p class="text-gray-400 hover:text-white max-w-xl mb-8">
      You will receive my FREE courses below automatically when you sign up to the AppSync Masterclass.
      Or you can also sign up to them yourself. <br>
      While you're here, why not check out my new course, 
      <span class="text-white font-bold">"Testing Serverless Architectures"</span> and learn all
      the tricks to testing serverless?
    </p>
    
    <div class="w-full grid grid-cols-2 gap-y-5 justify-items-center">
      <div class="max-w-lg ml-20">
        <h3 class="text-2xl font-bold mb-3">Learn you some Lambda best practices (FREE)</h3>
        <p class="text-gray-400 hover:text-white">
          Become an AWS Lambda blackbelt in bite-sized lessons.<br>
          It's easy to get started with AWS Lambda and quickly ship something. But 
          the thing is, there are lots of hidden decisions that can bite you in the ass 
          later if you don't think about them.
        </p>

        <a href="https://theburningmonk.thinkific.com/courses/learn-you-some-lambda?source=AppSyncMasterclass"
          class="button mt-3 block w-fit py-2 px-3 text-lg font-semibold text-center"
          target="_blank"
          @click="trackEvent('llama-more-detail')">More detail</a>
      </div>
      <div class="max-w-lg mr-20">
        <h3 class="text-2xl font-bold mb-3">Complete guide to AWS Step Functions (FREE)</h3>
        <p class="text-gray-400 hover:text-white">
          Learn how to build complex business workflows using AWS Step Functions.
          Learn the ins and outs of Step Functions. By the end of the course, you 
          should have a good grasp of how it works, when to use it, and design patterns 
          and best practices to help you get the most out of the Step Functions service.
        </p>

        <a href="https://theburningmonk.thinkific.com/courses/complete-guide-to-aws-step-functions?source=AppSyncMasterclass"
          class="button mt-3 block w-fit py-2 px-3 text-lg font-semibold text-center"
          target="_blank"
          @click="trackEvent('step-functions-more-detail')">More detail</a>
      </div>
      <div class="max-w-lg ml-20">
        <h3 class="text-2xl font-bold mb-3">Testing Serverless Architectures (NEW)</h3>
        <p class="text-gray-400 hover:text-white">
          Having no tests or a poorly thought-out testing strategy is a common reason 
          why serverless projects fail in the real world. Think of all the hours you'd 
          spend on manual testing and fixing flaky tests, or the time it'd take to 
          troubleshoot critical bugs that creep into production because of missing tests. 
          Testing serverless architectures can be straightforward, with the right strategy. 
          Save yourself years of trial-and-error.
          <span class="text-white font-bold">Learn what works in a matter of hours.</span>
        </p>

        <a href="https://testserverlessapps.com/?utm_campaign=othercourses&utm_source=appsyncmasterclass.com&utm_medium=more-details-button"
          class="button mt-3 block w-fit py-2 px-3 text-lg font-semibold text-center"
          target="_blank"
          @click="trackEvent('testing-serverless-more-detail')">More detail</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    trackEvent(eventName) {
      switch (eventName) {
        case 'llama-more-detail':
          global.fathom.trackGoal('H8XWYJKJ', 0)
          break
        case 'step-functions-more-detail':
          global.fathom.trackGoal('ELAA6NIA', 0)
          break
        case 'testing-serverless-more-detail':
          global.fathom.trackGoal('CIHNWGHV', 0)
          break
        default:
          break
      }
    }
  }
}
</script>
