const pageView = (page, pageName, affiliateId) => {
  if (global.rudderanalytics) {    
    global.rudderanalytics.page(
      page,
      pageName,
      {
        affiliateId
      }
    )
  } else {
    console.error('rudderstack is not found')
  }
}

export {
  pageView
}