<template>
  <div id="stats" class="mt-16 md:mt-32 lg:my-64">
    <div class="max-w-7xl mx-auto py-12 px-4">
      <div class="max-w-4xl mx-auto text-center">
        <h2 class="text-xl md:text-3xl lg:text-4xl font-bold text-white">
          Serverless technologies let you build scalable and performant applications 
          without managing any infrastructure. GraphQL lets you build powerful APIs 
          that are easy to use and extend. Together, they're the perfect tool for 
          building modern applications.
        </h2>
      </div>
      <dl class="mt-10 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-2 sm:gap-8">
        <div class="flex flex-col">
          <dd class="order-1 text-5xl font-extrabold">{{ lessons }}+</dd>
          <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-400">in-depth lessons</dt>
        </div>
        <div class="flex flex-col mt-10 sm:mt-0">
          <dd class="order-1 text-5xl font-extrabold">{{ hoursOfContent }}</dd>
          <dt class="order-2 mt-2 text-lg leading-6 font-medium text-gray-400">hours of content</dt>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatesComponent',
  props: [
    'lessons',
    'hoursOfContent'
  ]
}
</script>
