<template>
  <div id="tech-logos" class="mt-32">
    <div class="max-w-7xl mx-auto">
      <div>
        <h1 class="max-w-5xl mx-auto text-center text-2xl md:text-4xl font-extrabold">
          Build a Twitter clone using these modern technologies
        </h1>
        <ul class="max-w-4xl mx-auto mt-10 grid grid-cols-4 gap-x-4 lg:gap-x-8 gap-y-8">
          <TechnologyLogo v-for="logo in logos"
            v-bind:key="logo.name"
            v-bind:name="logo.name"
            v-bind:svg="logo.svg" />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TechnologyLogo from './TechnologyLogo.vue'

export default {
  components: {
    TechnologyLogo
  },
  data() {
    return {
      logos: [{
        name: 'Lambda',
        svg: 'v1658840931/lambda-logo.svg'
      }, {
        name: 'AppSync',
        svg: 'v1658840932/appsync-logo.svg'
      }, {
        name: 'Cognito',
        svg: 'v1658840932/cognito-logo.svg'
      }, {
        name: 'DynamoDB',
        svg: 'v1658840932/dynamodb-logo.svg'
      }, {
        name: 'Vue.js',
        svg: 'v1658840938/vuejs-logo.svg'
      }, {
        name: 'Tailwind CSS',
        svg: 'v1658840938/tailwind-logo.svg'
      }, {
        name: 'Serverless Framework',
        svg: 'v1658840938/serverless-logo.svg'
      }, {
        name: 'Amplify',
        svg: 'v1658840937/amplify-logo.svg'
      }]
    }
  }
}
</script>
