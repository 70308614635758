<template>
  <div id="logos" class="pt-16 md:pt-32 px-6 lg:px-8 max-w-7xl mx-auto">
    <h3 class="text-center text-lg font-medium">with students from these companies</h3>

    <ul class="grid grid-cols-2 md:grid-cols-4 md:gap-16">
      <Logo v-bind:company="`Amazon`" v-bind:logo="`v1658840936/amazon-logo.svg`" />
      <Logo v-bind:company="`IBM`" v-bind:logo="`v1658840935/ibm-logo.svg`" />
      <Logo v-bind:company="`Taco Bell`" v-bind:logo="`v1658840933/taco-bell-logo.svg`" />
      <Logo v-bind:company="`Capital One`" v-bind:logo="`v1658840933/capital-one-logo.svg`" />
      <Logo v-bind:company="`Trend Micro`" v-bind:logo="`v1658840934/trend-micro-logo.svg`" />
      <Logo v-bind:company="`Vanguard`" v-bind:logo="`v1658840932/vanguard-logo.svg`" />
      <Logo v-bind:company="`Polestar`" v-bind:logo="`v1658840936/polestar-logo.svg`" />
      <Logo v-bind:company="`Accenture`" v-bind:logo="`v1658840932/accenture-logo.svg`" />
      <Logo v-bind:company="`Lumigo`" v-bind:logo="`v1658840936/lumigo-logo.svg`" />
      <Logo v-bind:company="`Lapse`" v-bind:logo="`v1658840934/lapse-logo.svg`" />
      <Logo v-bind:company="`TecAlliance`" v-bind:logo="`v1658840935/tecalliance-logo.svg`" />
      <Logo v-bind:company="`Stedi`" v-bind:logo="`v1658840935/stedi-logo.svg`" />
    </ul>
  </div>
</template>

<script>
import Logo from './Logo.vue'

export default {
  name: 'LogosComponent',
  components: {
    Logo
  },
}
</script>
