let country

const getCountry = async () => {
  try {
    if (country) {
      return country
    } else {
      country = await fetch("https://paddle-check-price.appsyncmasterclass.workers.dev")
        .then(data => data.text())
  
      return country
    }
  } catch (err) {
    console.error('failed to get customer country', err)
    return null
  }
}

const getCookie = (name) => {
  const value = "; " + document.cookie
  const parts = value.split("; " + name + "=")
  return parts.length === 2 
    ? parts.pop().split(";").shift()
    : undefined
}

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const getParameterByName = (name, url = window.location.href) => {
  name = name.toLowerCase().replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url.toLowerCase())
  if (!results) {
    return null
  }
  if (!results[2]) {
    return ''
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export {
  getCountry,
  getCookie,
  setCookie,
  getParameterByName
}