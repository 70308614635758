<template>
  <div id="preview" class="lg:mt-32">
    <div class="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-xl font-extrabold">Preview content</h2>

      <div class="pt-10">
        <dl class="space-y-10 md:space-y-0 grid grid-cols-1 lg:grid-cols-2 gap-x-8 md:gap-y-12">
          <div class="relative">
            <video preload="none" controls class="w-full aspect-video" 
                   poster="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/video-thumb-modelling-graphql-schema.png">
              <source src="https://d1z83i8whk5gnr.cloudfront.net/video-model-graphql-schema.mp4" type="video/mp4">
              <track label="English" kind="subtitles" srclang="en" src="/subtitles/video-model-graphql-schema.vtt" default>
              Your browser does not support the video tag.
            </video>
            <p class="mt-3 text-gray-300 font-extrabold">How to model the Twitter API in a GraphQL schema</p>
          </div>

          <div class="relative">
            <video preload="none" controls class="w-full aspect-video" 
                   poster="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/video-thumb-what-is-graphql.png">
              <source src="https://d1z83i8whk5gnr.cloudfront.net/video-what-is-graphql.mp4" type="video/mp4">
              <track label="English" kind="subtitles" srclang="en" src="/subtitles/video-what-is-graphql.vtt" default>
              Your browser does not support the video tag.
            </video>
            <p class="mt-3 text-gray-300 font-extrabold">What is GraphQL?</p>
          </div>

          <div class="relative">
            <video preload="none" controls class="w-full aspect-video" 
                   poster="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/video-thumb-what-is-appsync.png">
              <source src="https://d1z83i8whk5gnr.cloudfront.net/video-what-is-appsync.mp4" type="video/mp4">
              <track label="English" kind="subtitles" srclang="en" src="/subtitles/video-what-is-appsync.vtt" default>
              Your browser does not support the video tag.
            </video>
            <p class="mt-3 text-gray-300 font-extrabold">What is AWS AppSync?</p>
          </div>

          <div class="relative">
            <video preload="none" controls class="w-full aspect-video" 
                   poster="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/video-thumb-what-are-subscriptions.png">
              <source src="https://d1z83i8whk5gnr.cloudfront.net/video-what-are-appsync-subscriptions.mov" type="video/mp4">
              <track label="English" kind="subtitles" srclang="en" src="/subtitles/video-what-are-appsync-subscriptions.vtt" default>
              Your browser does not support the video tag.
            </video>
            <p class="mt-3 text-gray-300 font-extrabold">How does AppSync Subscription work?</p>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
