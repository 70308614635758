<template>
  <div class="max-w-full mx-auto lg:mt-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
    <div v-bind:key="testimonial.name" v-for="testimonial in testimonials">
      <Testimonial 
        v-bind:name="testimonial.name"
        v-bind:tagline="testimonial.tagline"
        v-bind:quote="testimonial.quote"
        v-bind:img="testimonial.img" />
    </div>
  </div>
</template>

<script>
import Testimonial from './Testimonial.vue'

export default {
  name: 'TestimonialsComponent',
  props: [
    'testimonials',
  ],
  components: {
    Testimonial,
  }
}
</script>
