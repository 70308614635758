<template>
  <div id="what-you-will-build" class="py-16 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-2xl font-extrabold tracking-tight sm:text-3xl">Build a full-stack Twitter clone</h3>
          <p class="mt-3 text-lg ">Implement features such as user registration, managing user profile, post tweets, reply/retweet/like tweets, follow/unfollow users, hashtags, search, direct messages and notifications.</p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <!-- Fontawesome: user -->
                  <svg class="w-7 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                  </svg>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium ">User authentication</p>
              </dt>
              <dd class="mt-2 ml-16 text-base ">Implement user registration, sign-in, sign-out flows using Cognito and Amplify. Including the 3-step UI flow for signing up to Twitter.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <!-- Fontawesome: user pen -->
                  <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M223.1 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 223.1 256zM274.7 304H173.3C77.61 304 0 381.7 0 477.4C0 496.5 15.52 512 34.66 512h286.4c-1.246-5.531-1.43-11.31-.2832-17.04l14.28-71.41c1.943-9.723 6.676-18.56 13.68-25.56l45.72-45.72C363.3 322.4 321.2 304 274.7 304zM371.4 420.6c-2.514 2.512-4.227 5.715-4.924 9.203l-14.28 71.41c-1.258 6.289 4.293 11.84 10.59 10.59l71.42-14.29c3.482-.6992 6.682-2.406 9.195-4.922l125.3-125.3l-72.01-72.01L371.4 420.6zM629.5 255.7l-21.1-21.11c-14.06-14.06-36.85-14.06-50.91 0l-38.13 38.14l72.01 72.01l38.13-38.13C643.5 292.5 643.5 269.7 629.5 255.7z"/>
                  </svg>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium ">Manage user profile</p>
              </dt>
              <dd class="mt-2 ml-16 text-base ">Load user profile with tweets; allow users to edit their profile details and upload profile images to S3.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="logo absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <!-- Fontawesome: tweet -->
                  <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/>
                  </svg>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium ">Tweeting</p>
              </dt>
              <dd class="mt-2 ml-16 text-base ">Users can send tweets, reply to tweets or like a tweet. Tweets would be distributed to followers' timelines.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <!-- Fontawesome: users -->
                  <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z"/>
                  </svg>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium ">Social features - follow, DMs</p>
              </dt>
              <dd class="mt-2 ml-16 text-base ">Users can follow each other and receive tweets into their timeline, and they can private message each other with direct messages.</dd>
            </div>

            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <!-- Fontawesome: message -->
                  <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M511.1 63.1v287.1c0 35.25-28.75 63.1-64 63.1h-144l-124.9 93.68c-7.875 5.75-19.12 .0497-19.12-9.7v-83.98h-96c-35.25 0-64-28.75-64-63.1V63.1c0-35.25 28.75-63.1 64-63.1h384C483.2 0 511.1 28.75 511.1 63.1z"/>
                  </svg>
                </div>
                <p class="ml-16 text-lg leading-6 font-medium ">Real-time notifications</p>
              </dt>
              <dd class="mt-2 ml-16 text-base ">Implement real-time notifications using websockets.</dd>
            </div>
          </dl>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <img class="relative mx-auto border-2 border-white" height="600" loading="lazy"
            src="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/build-twitter-clone.png">
        </div>
      </div>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-extrabold tracking-tight sm:text-3xl">Get production-ready!</h3>
            <p class="mt-3 text-lg ">Getting an app to work is the easy part. The hard part is in getting it production-ready. Can it scale with more users? Can you troubleshoot issues quickly? Does it work well on both desktop and mobile?</p>

            <dl class="mt-10 space-y-10">
              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <!-- Fontawesome: mobile screen button -->
                    <svg class="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path d="M304 0h-224c-35.35 0-64 28.65-64 64v384c0 35.35 28.65 64 64 64h224c35.35 0 64-28.65 64-64V64C368 28.65 339.3 0 304 0zM192 480c-17.75 0-32-14.25-32-32s14.25-32 32-32s32 14.25 32 32S209.8 480 192 480zM304 64v320h-224V64H304z"/>
                    </svg>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium ">Progressive Web Application (PWA)</p>
                </dt>
                <dd class="mt-2 ml-16 text-base ">Make the UI work well on desktop, tablets and mobile devices so the user always get a good experience no matter the form factor.</dd>
              </div>

              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <!-- Fontawesome: chart-line -->
                    <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z"/>
                    </svg>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium ">Analytics tracking</p>
                </dt>
                <dd class="mt-2 ml-16 text-base ">Gain insights into how users are using your application. Use Kinesis Firehose to ingest click-stream events and analyze them using Athena.</dd>
              </div>

              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <!-- Fontawesome: binoculars -->
                    <svg class="w-6 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M416 48C416 39.13 408.9 32 400 32h-64C327.1 32 320 39.13 320 48V96h96.04L416 48zM63.88 160.1C61.34 253.9 3.5 274.3 0 404V448c0 17.6 14.4 32 32 32h128c17.6 0 32-14.4 32-32V128H95.88C78.26 128 64.35 142.5 63.88 160.1zM448.1 160.1C447.6 142.5 433.7 128 416.1 128H320v320c0 17.6 14.4 32 32 32h128c17.6 0 32-14.4 32-32v-44C508.5 274.3 450.7 253.9 448.1 160.1zM224 288h64V128H224V288zM176 32h-64C103.1 32 96 39.13 96 48L95.96 96H192V48C192 39.13 184.9 32 176 32z"/>
                    </svg>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium ">Observability</p>
                </dt>
                <dd class="mt-2 ml-16 text-base ">Set up CloudWatch alerts and dashboards; investigate performance issues with X-Ray; get detailed resolver metrics with CloudWatch Insights.</dd>
              </div>

              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <!-- Fontawesome: bolt lightning -->
                    <svg class="w-5 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path d="M381.2 172.8C377.1 164.9 368.9 160 360 160h-156.6l50.84-127.1c2.969-7.375 2.062-15.78-2.406-22.38S239.1 0 232 0h-176C43.97 0 33.81 8.906 32.22 20.84l-32 240C-.7179 267.7 1.376 274.6 5.938 279.8C10.5 285 17.09 288 24 288h146.3l-41.78 194.1c-2.406 11.22 3.469 22.56 14 27.09C145.6 511.4 148.8 512 152 512c7.719 0 15.22-3.75 19.81-10.44l208-304C384.8 190.2 385.4 180.7 381.2 172.8z"/>
                    </svg>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium">Performance optimization</p>
                </dt>
                <dd class="mt-2 ml-16 text-base ">Optimize Lambda cold start time and use AppSync's per-resolver caching to improve scalability and performance.</dd>
              </div>
            </dl>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <img class="relative mx-auto border-2 border-white" width="570" loading="lazy"
              src="https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto/get-production-ready.png">
          </div>
        </div>
      </div>
    </div>

    <div class="mt-14 w-full flex flex-col justify-center">
      <p class="text-lg font-medium text-center">Still not convinced? Why not check out a <a class="link text-xl" href="https://demo.appsyncmasterclass.com" target="_blank" @click="trackEvent()">live demo</a> of the app you're gonna build.</p>
    </div>
  </div>  
</template>

<script>
export default {
  methods: {
    trackEvent() {
      global.fathom.trackGoal('TZAH39IK', 0)
    }
  }
}
</script>
