<template>
  <tr>
    <th v-if="!url" class="py-5 px-6 font-normal text-left" scope="row">{{ name }}.</th>
    <th v-if="url" class="py-5 px-6 font-normal text-left" scope="row">
      <a class="link" :href="url" target="_blank">{{ name }}</a>.
    </th>

    <td class="py-5 px-6" v-if="inBasic">
      <!-- Heroicon name: solid/check -->
      <svg class="h-5 w-5 text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Included in Basic</span>
    </td>

    <td class="py-5 px-6" v-if="!inBasic">
      <!-- Heroicon name: solid/minus -->
      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
        fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Not included in Basic</span>
    </td>

    <td class="py-5 px-6" v-if="inPlus">
      <!-- Heroicon name: solid/check -->
      <svg class="h-5 w-5 text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Included in Plus</span>
    </td>

    <td class="py-5 px-6" v-if="!inPlus">
      <!-- Heroicon name: solid/minus -->
      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
        fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Not included in Plus</span>
    </td>

    <td class="py-5 px-6" v-if="!notInPremium">
      <!-- Heroicon name: solid/check -->
      <svg class="h-5 w-5 text-green-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
        aria-hidden="true">
        <path fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Included in Premium</span>
    </td>

    <td class="py-5 px-6" v-if="notInPremium">
      <!-- Heroicon name: solid/minus -->
      <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
        fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
      </svg>
      <span class="sr-only">Not included in Premium</span>
    </td>
  </tr>
</template>

<script>
export default {
  props: [
    'name',
    'url',
    'inBasic',
    'inPlus',
    'notInPremium'
  ]
}
</script>