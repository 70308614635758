<template>
  <div class="px-10 lg:px-32">
    <h1 class="pt-32 text-3xl lg:text-4xl font-extrabold">AppSync Masterclass plus package</h1>

    <div class="grid grid-cols-1 gap-y-10 lg:grid-cols-2 mt-14">
      <WhatsIncluded 
        v-bind:listHeading="`This is what you will learn in the plus package:`"
        v-bind:contents="includedContents"
        v-bind:includeSneakPeek=true />

      <CheckOutPaddle
        v-bind:productId="productId" />
    </div>

    <div class="mt-20">
      <h2 class="text-3xl lg:text-4xl font-extrabold">What's not included in the plus package</h2>

      <div class="mt-10">
        <p>If you plan to run AppSync in production then you should check out the <span class="text-xl text-white font-bold">Premium package</span>.</p>
        <p>Which includes additional lessons such as:</p>

        <div v-for="content in premiumContents" v-bind:key="content.concept">
          <h3 class="text-lg font-bold my-2">{{ content.concept }}</h3>
          <ul class="list-disc list-inside">
            <li v-for="topic in content.topics" v-bind:key="topic">{{ topic }}</li>
          </ul>
        </div>
      </div>

      <div class="mt-10">
        <a href="#" class="button px-8 py-3 text-base font-medium md:py-4 md:text-lg md:px-10"
          @click.prevent="goToHomePackages"> Compare the packages </a>
      </div>
    </div>

    <Footer v-bind:showLogo=true />
  </div>
</template>

<script>
import WhatsIncluded from '../components/CheckOutWhatsIncluded.vue'
import CheckOutPaddle from '../components/CheckOutPaddle.vue'
import Footer from '../components/Footer.vue'
import { ProductIds } from '../libs/constants'

export default {
  components: {
    WhatsIncluded,
    CheckOutPaddle,
    Footer,
  },
  data() {
    return {
      productId: ProductIds.PlusPackage,
      includedContents: [
        { 
          concept: 'Concepts', 
          topics: [
            'What is GraphQL, how does it compare with REST?',
            'What is AppSync, how does it compare with API Gateway?',
            'How to design a GraphQL schema?'
          ]
        },
        { 
          concept: 'Testing', 
          topics: [
            'How to test AppSync APIs locally?',
            'How to unit test VTL templates?',
            'How to write integration tests for Lambda functions?',
            'How to test AppSync APIs with end-to-end tests?'
          ]
        },
        { 
          concept: 'Security', 
          topics: [
            'What is Cognito?',
            'How to use Cognito to authenticate users?',
            'When to use API Keys and AWS_IAM auth?',
          ]
        },
        { 
          concept: 'CI/CD', 
          topics: [
            'How to use the Serverless framework to automate deployment?',
            'How to set up CI/CD pipeline with Github Actions?',
            'How to promote changes through the stages?'
          ]
        },
        { 
          concept: 'Performance and Cost', 
          topics: [
            'How to optimize performance, cost and scalability with caching.',
            'How to optimize Lambda cold starts?',
          ]
        },
        { 
          concept: 'Real-time updates', 
          topics: [
            'How to use GraphQL subscriptions to build real-time apps?',
          ]
        },
        { 
          concept: 'Search', 
          topics: [
            'How to build search features with Algolia?',
          ]
        },
      ],
      premiumContents: [
        { 
          concept: 'DevOps', 
          topics: [
            'How to configure custom domain names for AppSync?',
            'How to version AppSync APIs?',
            'How to monitor and debug AppSync APIs in production?',
            'How to capture per-resolver metrics for AppSync?',
            'What metrics and alerts do you need to monitor AppSync APIs?',
            'How to set up CloudWatch dashboards for your AppSync APIs?',
            'How to use X-Ray to investigate performance issues?',
          ]
        },
        { 
          concept: 'Progressive Web App', 
          topics: [
            'How to make the UI work well for both mobile and desktop?',
          ]
        },
        { 
          concept: 'Analytics', 
          topics: [
            'How to collect click-stream events to help you understand how users are using your application?',
          ]
        },
        { 
          concept: 'Multi-tenancy', 
          topics: [
            'How to model multi-tenant APIs?',
            'How to use Cognito in a multi-tenant app?',
          ]
        },
      ],
      otherCourses: [
        'llama',
        'step-functions'
      ]
    }
  },
  mounted() {
    document.title = 'Check out (plus package)'
  },
  methods: {
    goToHomePackages() {
      global.fathom.trackGoal('2XOSQLX0', 0)
      this.$router.push('/#packages')
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}
</style>