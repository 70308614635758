<template>
  <div class="pt-36 max-w-5xl mx-auto min-h-screen grid grid-cols-1 justify-items-center gap-10 content-start">
    <h1 class="text-center text-4xl font-extrabold">Thank you for enrolling into the {{ courseName }}!</h1>
    <img v-if="courseName === 'AppSync Masterclass'" class="" src="/images/welcome-to-appsync-masterclass.png" />

    <div class="text-lg px-5">
      <h2 class="text-center text-3xl font-bold mb-5">What happens now?</h2>
      <p>By now you should have received a payment receipt from <span class="font-bold">Thinkific</span> by email.
         Please keep this safe as proof of your purchase.</p>
      <p>If you wish to upgrade your package later, you also need this so I can give you the right discount.</p>
      <p>To get access to the course material, head over to <a class="link" href="https://theburningmonk.thinkific.com" target="_blank">theburningmonk.thinkific.com</a>.</p>
      <p>If you haven't previously registered an account, then you should have received an email from <span class="font-bold">Thinkific</span> with instructions on how to register and sign in.</p>
      <p>If you have an account already, then you should see the course in your Dashboard.</p>
    </div>
    
    <img src="/images/thinkific-dashboard.png"/>

    <div class="mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between">
      <p class="mt-8 text-base md:mt-0 md:order-1">&copy; 2020 theburningmonk LTD. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      courseName: ''
    }
  },
  mounted() {
    document.title = 'Thank you!'
    this.courseName = this.$route.query.course || 'AppSync Masterclass'
  }
}
</script>

<style scoped>
p {
  margin-bottom: 1em;
}
</style>