<template>
  <div class="mt-10 lg:mt-16 pt-6">
    <div class="flex justify-end">
      <a href="https://theburningmonk.thinkific.com/enrollments"
         target="_blank"
         class="hidden md:block pr-20 text-lg font-semibold text-center hover:text-white"
         @click="trackEvent('log-in')">
        Student log in
      </a>
    </div>

    <main class="mt-10 mx-auto max-w-7xl px-4">
      <div class="grid grid-cols-1 justify-items-center">
        <img alt="logo" 
          class="h-44 md:h-52 md:mt-10 mb-5" 
          src="/images/appsync-masterclass-logo.svg">

        <h1 class="w-full text-center text-3xl tracking-tight font-extrabold
                  md:text-6xl md:my-5">
          Build scalable and performant full-stack serverless applications
        </h1>
        <p class="mt-3 max-w-md mx-auto text-center text-base text-gray-300 
                  sm:text-lg md:mt-5 md:text-2xl md:max-w-4xl">
          Learn how to use modern technologies such as <span class="text-white font-bold">AppSync</span>, 
          <span class="text-white font-bold">Lambda</span>, 
          <span class="text-white font-bold">DynamoDB</span> and 
          <span class="text-white font-bold">Vue.js</span>
          to build performant and scalable full-stack applications by building a 
          <span class="text-white font-bold">Twitter clone</span> from scratch.          
        </p>
      </div>
      <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
        <div class="rounded-md shadow">
          <a href="#pricing" class="button w-full flex items-center justify-center px-8 py-3 text-base md:py-4 md:text-xl md:px-10"
             @click="trackEvent('get-course')">
            Get the course
          </a>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'HeroComponent',
  methods: {
    trackEvent(eventName) {
      switch (eventName) {
        case 'log-in':
          global.fathom.trackGoal('UMNAEMOM', 0)
          break
        case 'get-course':
          global.fathom.trackGoal('VBTAZ6PB', 0)
          break
        default:
          break
      }
    }
  }
}
</script>
