<template>
  <div class="py-4 md:py-10 px-6 md:flex md:flex-col lg:px-10">
    <blockquote class="mt-6 md:flex md:flex-col">
      <div class="relative text-lg md:text-xl font-semibold md:flex-grow">
        <span class="relative italic text-gray-400 hover:text-white">{{ quote }}</span>
      </div>
      <footer class="mt-4">
        <div class="flex items-start">
          <div class="flex-shrink-0 inline-flex">
            <img class="h-14 w-14 rounded-full border-4 hover:border-white" 
              loading="lazy"
              :src="`https://res.cloudinary.com/appsyncmasterclass/image/upload/f_auto,c_scale,h_56,w_56/${img}`">
          </div>
          <div class="ml-4">
            <div class="text-lg font-bold">{{ name }}</div>
            <div class="text-base font-bold text-gray-400">{{ tagline }}</div>
          </div>
        </div>
      </footer>
    </blockquote>
  </div>
</template>

<script>
export default {
  name: 'TestimonialComponent',
  props: [
    'name', 
    'tagline', 
    'quote', 
    'img'
  ],
}
</script>
