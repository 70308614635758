<template>
  <li class="relative flex justify-center items-top h-28">
    <img class="absolute w-20 opacity-75 hover:opacity-100" 
      loading="lazy"
      :src="`https://res.cloudinary.com/appsyncmasterclass/image/upload/${svg}`"
      :alt="name">
    <p class="absolute bottom-0 text-center text-sm md:text-base lg:text-xl">{{ name }}</p>
  </li>
</template>

<script>
export default {
  props: ['name', 'svg'],
}
</script>
