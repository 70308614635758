<template>
  <div class="relative overflow-hidden px-3 md:px-10 lg:px-32">
    <Hero />    

    <Testimonials v-bind:testimonials="testimonials1" />

    <Stats v-bind:lessons="200" v-bind:hoursOfContent="35" />

    <TechnologyLogos />

    <WhatYouWillLearn />

    <div class="max-w-md mx-auto flex justify-center">
      <a href="#pricing" class="button w-full px-10 py-3 text-base text-center font-medium md:py-4 md:text-lg"
          @click="trackEvent('get-course-mid-1')">
        Level up your serverless skills
      </a>
    </div>

    <PreviewContents />

    <Testimonials v-bind:testimonials="testimonials2" />

    <WhatYouWillBuild />

    <About />

    <Testimonials v-bind:testimonials="testimonials3" />

    <Pricing />

    <FAQ />

    <Logos />

    <Footer />
  </div>
</template>

<script>
import Hero from '../components/Hero.vue'
import Logos from '../components/Logos.vue'
import Pricing from '../components/Pricing.vue'
import Testimonials from '../components/Testimonials.vue'
import Stats from '../components/Stats.vue'
import TechnologyLogos from '../components/TechnologyLogos.vue'
import WhatYouWillLearn from '../components/WhatYouWillLearn.vue'
import WhatYouWillBuild from '../components/WhatYouWillBuild.vue'
import PreviewContents from '../components/PreviewContents.vue'
import FAQ from '../components/FAQ.vue'
import About from '../components/About.vue'
import Footer from '../components/Footer.vue'
import { getCookie, setCookie, getParameterByName } from '../libs/utils'
import { pageView } from '../libs/analytics'

export default {
  name: 'HomeView',
  components: {
    Hero,
    Logos,
    Testimonials,
    Pricing,
    Stats,
    PreviewContents,
    TechnologyLogos,
    WhatYouWillLearn,
    WhatYouWillBuild,
    FAQ,
    About,
    Footer,
  },
  data() {
    return {
      testimonials1: [{
        name: "Geoff Baskwill",
        tagline: "Trend Micro",
        quote: "Doing the AppSync Masterclass course has given me the confidence and skills to start a project I've been wanting to do for 5 years.",
        img: "v1658840931/geoff-baskwill.jpg"
      }, {
        name: "Djordje Nikolic",
        tagline: "Full Stack Developer",
        quote: "It's a very unique course. Yan uses best practices (such as testing) to really prepare you to create safe, production-ready applications in AppSync.",
        img: "v1658840930/djordje-nikolic.png"
      }, {
        name: "Orr Levinger",
        tagline: "Senior Software Engineer",
        quote: "This is by far the most robust and detailed course on AWS AppSync. Love the step by step examples and walkthrough. Best AppSync serverless course out there!",
        img: "v1658840930/orr-levinger.png"
      }, {
        name: "Anders Quist",
        tagline: "Polestar",
        quote: "You can tell it's based on real-world experience and not from textbook examples. Very insightful and stuffed with insights needed to be successful in any larger AppSync project.",
        img: "v1658840937/anders-quist.jpg"
      }, {
        name: "David Kerr",
        tagline: "Site Reliability Engineer",
        quote: "This course is great for learning AppSync in a very practical way - by building out a Twitter clone step-by-step. If you are thinking about starting a new side project, take this course first!",
        img: "v1658840931/david-kerr.png"
      }, {
        name: "Sarvesh Kesharwani",
        tagline: "Amazon",
        quote: "If you can only afford 1 course, I would suggest you go for this one. I have been watching online tutorials for many years but never seen anything like it!",
        img: "v1658840930/sarvesh-kesharwani.png"
      }],
      testimonials2: [{
        name: "Santiago Pergamo",
        tagline: "Full Stack Developer",
        quote: "Thanks for such great content! Your lessons are full of concepts we need to understand to effectively use AppSync in a project.",
        img: "v1658840937/santiago-pergamo.jpg"
      }, {
        name: "Dave O'Keeffe",
        tagline: "Principal Software Developer",
        quote: "Very in-depth course so far. The course moves quickly, but not so fast that you can't develop along with it.",
        img: "v1658840930/dave-okeeffe.png"
      }, {
        name: "Tobias Geiselmann",
        tagline: "Head of Cloud Solutions & Services",
        quote: "I just bought the Appsync Masterclass! Seriously, I took a look at the free lessons and the Github repository - how could I not buy this? Now I'm gonna take my Appsync skills to the next level!",
        img: "v1658840934/tobias-geiselmann.jpg"
      }, {
        name: "Susan McNab",
        tagline: "Consultant",
        quote: "This course is full of information that would take years of trial and error to accumulate on your own. Yan builds up practical knowledge alongside muscle memory in this very well thought out course. The only thing missing is Yan's lovely cat.",
        img: "v1658840937/susan-mcnab.jpg"
      }, {
        name: "Søren Andersen",
        tagline: "Freelance IT Consultant",
        quote: "Extremely in-depth, yet still very approachable. This compelling tech stack is king of both productivity and possibilities. Hats off to Yan and Gerard for delivering this awesome course packed with knowledge and insights. Much, much recommended!",
        img: "v1658840930/soren-andersen.png"
      }, {
        name: "Jakub Wilkowski",
        tagline: "Tech Lead",
        quote: "It's just the beginning and I already learned a lot of new things and also improved in what I already felt more familiar with. Really enjoyed the part about big effort/low return of unit tests.",
        img: "v1658840937/jakub-wilkowski.jpg"
      }],
      testimonials3: [{
        name: "Andreas Grimm",
        tagline: "Cloud Solutions Architect",
        quote: "Yan is simply awesome. do yourself something good and take the chance to level up on his courses.",
        img: "v1658840937/andreas-grimm.jpg"
      }, {
        name: "Vishal Gautam",
        tagline: "Full Stack Developer",
        quote: "One of the best resource to learn AppSync. Thank you Yan for such an amazing course!",
        img: "v1658840930/vishal-gautam.png"
      }, {
        name: "Toomas Haide",
        tagline: "Product Engineer",
        quote: "I finally got around to start on your AppSync Masterclass course. I wish this course existed back in June when I embarked on my AWS journey. Worth every single penny.",
        img: "v1658840930/toomas-haide.png"
      }, {
        name: "Diego Maia",
        tagline: "Senior Java Developer",
        quote: "The course content is great, but the awesome part is seeing Yan's thinking process. How he structures the project and using cloud best practices.",
        img: "v1658840937/diego-maia.jpg"
      }, {
        name: "George-Ovidiu Stoica",
        tagline: "Frontend Developer",
        quote: "You can tell that Yan invested a lot of effort into this course. Everything is well organized and explained. I am enjoying this course a lot and it has taught me a lot. If you need to get up to speed with serverless and AWS, this is the course I fully recommend.",
        img: "v1658840930/george-ovidiu-stoica.png"
      }, {
        name: "Zhuangda Zhu",
        tagline: "Apple",
        quote: "I am a total newbie when it comes to AppSync or AWS. But the way Yan breaks down his course is very thoughtful. It is reasonable to follow yet challenging and I learn new things everyday. Thanks Yan!",
        img: "v1658840937/zhuangda-zhu.jpg"
      }],
    }
  },
  mounted() {
    this.navEvents = new Set()
    this.navSections = {}

    let affiliateId = getCookie('affiliateId')
    if (!affiliateId) {
      affiliateId = getParameterByName('affiliateid')
      if (affiliateId) {
        // Set affiliateId in cookie for 30 days if it's found in the query string parameter
        setCookie('affiliateId', affiliateId, 30)
      }
    }

    console.log('affiliateId:', affiliateId)
    pageView('Home', 'HomeView', affiliateId)

    try {
      this.addNavSection('stats')
      this.addNavSection('what-you-will-learn')
      this.addNavSection('what-you-will-build')
      this.addNavSection('about')
      this.addNavSection('faq')
      this.addNavSection('pricing')
      this.addNavSection('logos')

      window.addEventListener('scroll', this.onScroll)
    } catch (err) {
      console.error('failed to capture element viewport')
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    addNavSection(id) {
      const { top } = this.getElementRect(id)
      if (top) {
        this.navSections[id] = top
      }
    },
    getElementRect(id) {
      const div = document.getElementById(id)
      const boundingRect = div?.getBoundingClientRect()
      return {
        top: boundingRect?.y + window.scrollY,
        bottom: boundingRect?.y + boundingRect?.height + window.scrollY
      }
    },
    onScroll() {
      for (const id in this.navSections) {
        if (window.scrollY >= this.navSections[id]) {
          this.trackNavEvent(id)
        }
      }
    },
    trackNavEvent(id) {
      if (this.navEvents.has(id)) {
        return
      }

      switch (id) {
        case 'stats':
          global.fathom.trackGoal('4AK1FC0B', 0)
          break
        case 'what-you-will-learn':
          global.fathom.trackGoal('ARLXYS3M', 0)
          break
        case 'what-you-will-build':
          global.fathom.trackGoal('ZBGBDUGV', 0)
          break
        case 'about':
          global.fathom.trackGoal('QKDAK23V', 0)
          break
        case 'faq':
          global.fathom.trackGoal('ELBUMN61', 0)
          break
        case 'pricing':
          global.fathom.trackGoal('UEQKSGMX', 0)
          break
        case 'logos':
          global.fathom.trackGoal('QXCYJ37K', 0)
          break
        default:
          console.log('unknown nav event', id)
          break
      }

      console.log('nav:', id)
      this.navEvents.add(id)
    },
    trackEvent(eventName) {
      switch (eventName) {
        case 'get-course-mid-1':
          global.fathom.trackGoal('L8YRMDB0', 0)
          break
        default:
          break
      }
    },
  }
}
</script>